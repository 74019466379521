import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '../../common/ALink';
import PlaceholderImage from '../../common/PlaceholderImage';
import { useRouter } from 'next/router';
import cn from 'classnames';

function FeaturedCard({ product, className }) {
  const router = useRouter();
  const { query } = router;

  return (
    <ALink
      href={
        product?.store_type === 'electronics'
          ? `${process.env.ELECTRONICS_URL}${product.slug}?${new URLSearchParams(query).toString()}`
          : `${process.env.FURNITURE_URL}/product/default${product.slug}?${new URLSearchParams(query).toString()}`
      }
      className={`featured-card-container ${className ? className : ''}`}>
      <PlaceholderImage
        width="100%"
        height="200px"
        position="relative"
        className={cn('fix-image', {
          'fix-height': product.store_type === 'electronics'
        })}
        src={product?.pictures[0]?.url}
        alt="Feature product"
      />
      <span className="featured-card-category text-uppercase">
        {product.categories[1]?.name || product.categories[0]?.name}
      </span>
      <p className="featured-card-name">{product.name}</p>

      <div className="price-box">
        {product?.sale_end?.sale_in_seconds > 0 ? (
          <>
            <span className="product-price">
              {'$' + product?.price[0]?.toFixed(2)}
            </span>
            <span className="old-price">
              {'$' + product?.price[1]?.toFixed(2)}
            </span>
            <div className="product-home-sale">
              <LazyLoadImage width={11} src="/images/fire.svg" alt="Hot Sale" />
              <span className="product-home-sale-text">
                Sale Ends in:
                <span className="product-home-sale-text-lower sale-text-small">
                  <span className="product-home-sale-text-bold sale-text-small">
                    {product.sale_end.days}
                  </span>
                  d{' '}
                  <span className="product-home-sale-text-bold sale-text-small">
                    {product.sale_end.hours}
                  </span>
                  h{' '}
                  <span className="product-home-sale-text-bold sale-text-small">
                    {product.sale_end.minutes}
                  </span>
                  m
                </span>
              </span>
            </div>
          </>
        ) : (
          <span className="product-price">
            {'$' + product?.price[0]?.toFixed(2)}
          </span>
        )}
      </div>
    </ALink>
  );
}

export default React.memo(FeaturedCard);
