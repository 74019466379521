import React from 'react';
import FeaturedCard from './featuredCard';

function FeaturedGallery({ goods }) {
  return (
    <div className="featured-gallery-container">
      {!!goods?.length &&
        goods.map((item, index) => (
          <FeaturedCard
            key={item.name + index}
            product={item}
            className={`featured-gallery-card featured-gallery-card-${index}`}
          />
        ))}
    </div>
  );
}

export default React.memo(FeaturedGallery);
