import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ALink from '../../common/ALink';
import { useRouter } from 'next/router';

function BestSellersSection() {
    const router = useRouter();
    const { query } = router; 

    return (
        <div className="best-container">
            <LazyLoadImage
                alt="electronics"
                src="images/best-electronic.png"
                className="best-absolute-img-left"
            />
            <div className="best-box">
                <h2 className="best-title font5">Our Best Sellers</h2>
                <p className="best-text font4">
                    Browse our most popular products, at the lowest prices, from the last
                    14 days
                </p>
                <div className="best-link-container">
                    <ALink
                        className="best-link font4"
                        href={`${process.env.ELECTRONICS_URL}?${new URLSearchParams(query).toString()}`}>
                        Electronics
                    </ALink>
                    <span className="best-separator"></span>
                    <ALink
                        className="best-link font4"
                        href={`${process.env.FURNITURE_URL}?${new URLSearchParams(query).toString()}`}>
                        Furniture
                    </ALink>
                </div>
            </div>
            <LazyLoadImage
                alt="furniture"
                src="images/best-furniture.png"
                className="best-absolute-img-right"
            />
        </div>
    );
}

export default React.memo(BestSellersSection);

