import React from 'react';
import ALink from '../../common/ALink';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useRouter } from 'next/router';

function StoreNavSection() {
  const router = useRouter();
  const { query } = router;

  return (
    <div className={'store-nav-container'}>
      <div className={'col-md-5 pictures-container'}>
        <figure>
          <LazyLoadImage
            alt="furniture"
            src={'/images/bedroom/bedroomGroup.png'}
            width="100%"
            height="auto"
          />
        </figure>
      </div>
      <div className={'col-md-5 store-msg-container'}>
        <h2 className="font5"> Your ideal furniture in a click</h2>
        <span className={'text-inf'}>
          Select your favorite styles with ease. Choose from a wide range of
          quality pieces. Find the perfect interior upgrade for your home.
        </span>
        <div>
          <ALink
            href={`${process.env.FURNITURE_URL}/home?${new URLSearchParams(query).toString()}`}
            className={'btn btn-custom font4'}>
            Shop now
          </ALink>
        </div>
      </div>
    </div>
  );
}

export default React.memo(StoreNavSection);