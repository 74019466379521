import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function WorkDescriptionSection({ id }) {
  return (
    <div className={'row store-nav-container work-section'} id={id}>
      <div className={'col-md-3 works-msg-container'}>
        <h2 className={'tittle-reverse font5'}> How does it work</h2>
        <span className={'text-inf font4'}>
          Our approval process is fast, easy and real-time. Bad or no credit is
          OK.
        </span>
      </div>

      <div className="shopping-steps">
        <div className="step step-1">
          <div className="step__icon icon-1"></div>
          <h3 className="step__title">Get pre-approved in real-time.</h3>
          <p className="step__description">
            The process is fast, easy and approval is instant.
          </p>
        </div>
        <div className="step step-2">
          <div className="step__icon icon-2"></div>
          <h3 className="step__title">Start Shopping.</h3>
          <p className="step__description">
            Select from over 1 Million new, brand name,
            <br />
            products.
          </p>
        </div>
        <div className="step step-3">
          <div className="step__icon icon-3"></div>
          <h3 className="step__title">Checkout.</h3>
          <p className="step__description">
            Sign your lease and make a small payment.
          </p>
        </div>
        <div className="step step-4">
          <div className="step__icon icon-4"></div>
          <h3 className="step__title">Electronics are fulfilled by Best Buy.</h3>
          <p className="step__description">
            Most products are available the same day.
          </p>

          <div className="step-4__cursor-icon">
            <img
              src="images\general\Vector 2551.png"
              alt="Cursor image"
              className="cursor-icon-1"
            />

            <img
              src="images\general\Vector 2552.png"
              alt="Cursor click image"
              className="cursor-icon-2"
            />
          </div>
        </div>
        <div className="step step-5">
          <div className="step__icon icon-5"></div>
          <h3 className="step__title">
            Furniture is shipped directly to your home.
          </h3>
          <p className="step__description">$0 is due at pickup or upon delivery.</p>
        </div>
      </div>

      {/* <div className={'col-md-9 pictures-large-container'}>
        <figure>
          <LazyLoadImage
            alt="furniture"
            src={'/images/home/how_work_img.svg'}
            width="100%"
            height="auto"
          />
        </figure>
      </div> */}
    </div>
  );
}

export default React.memo(WorkDescriptionSection);
