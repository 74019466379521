import React from 'react';
import ReviewCard from './reviewCard';
import { reviews } from '../../../utils/data/landing';
import { EmblaCarousel } from '../../features/carousel/emblaCarousel';

function ReviewsSection() {
  const reviewsList = reviews;

  return (
    <div className="review-container">
      <div className="review-box">
        <h2 className="review-title font5">
          Trusted by Thousands of Happy Customers
        </h2>
        <span className="review-text font4">
          We work hard to earn genuine 5-star reviews from quality customers
          like you 🙂
        </span>
      </div>

      <EmblaCarousel
        showButtons={true}
        totalSlides={reviewsList.length}
        slidesToScroll={3}
        options={{ loop: false }}>
        <div className="review-cards">
          {!!reviewsList.length &&
            reviewsList.map((item, index) => (
              <ReviewCard key={index} review={item} />
            ))}
        </div>
      </EmblaCarousel>
 
      {/* <ALink href={``} className="ghost-btn font4">
        Check all reviews
      </ALink> */}
    </div>
  );
}

export default React.memo(ReviewsSection);
