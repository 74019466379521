import React, { useRef, useEffect, useState } from 'react';


const CustomScrollbar = ({ children }) => {
  const scrollContentRef = useRef(null); 
  const thumbRef = useRef(null);  

  const [thumbHeight, setThumbHeight] = useState(85); 
  const [isScrollable, setIsScrollable] = useState(false); 

  useEffect(() => {
    const updateThumbSize = () => {
      const { scrollHeight, clientHeight } = scrollContentRef.current;
      
      if (scrollHeight > clientHeight) {
        setIsScrollable(true);
        const thumbHeight = (clientHeight / scrollHeight) * clientHeight;
        setThumbHeight(thumbHeight);
      } else {
        setIsScrollable(false);
      }
    };

    updateThumbSize();

    window.addEventListener('resize', updateThumbSize);
    return () => window.removeEventListener('resize', updateThumbSize);
  }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollContentRef.current;
    const thumbPosition = (scrollTop / scrollHeight) * clientHeight;
    thumbRef.current.style.top = `${thumbPosition}px`;
  };

  const handleThumbDrag = (e) => {
    e.preventDefault();
    const startY = e.clientY;
    const startTop = thumbRef.current.offsetTop;

    const onMouseMove = (moveEvent) => {
      const deltaY = moveEvent.clientY - startY;
      const { scrollHeight, clientHeight } = scrollContentRef.current;

      scrollContentRef.current.scrollTop =
        ((startTop + deltaY) / clientHeight) * scrollHeight;
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <div className="scroll-container">
      <div
        className="scroll-content"
        ref={scrollContentRef}
        onScroll={handleScroll}
      >
        {children}
      </div>
      {isScrollable && (
        <div className="custom-scrollbar">
          <div
            className="custom-thumb"
            ref={thumbRef}
            style={{ height: `${thumbHeight}px` }}
            onMouseDown={handleThumbDrag}
          ></div>
        </div>
      )}
    </div>
  );
};

export default CustomScrollbar;