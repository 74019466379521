import Head from "next/head";

const MetaHeader = ({title}) => {

  return (
    <Head>
      <title>{title} / BuyOnTrust</title>
      <meta property="og:title" content={title} key='og:title'/>
    </Head>
  );
};

export default MetaHeader;