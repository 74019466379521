import React from 'react';
import AdvBox from './advBox';

function AdvantageSection() {
  return (
    <div className={'advantage-container'}>
      <h2 className={'text-center font5'}>Why Buy On Trust?</h2>
      <span className={'adv-text font4'}>
        Choose from over a million top quality, brand name products, that
        elevate your lifestyle to new heights.
      </span>
      <div className={'adv-cards-container'}>
        <AdvBox
          title={'Bad credit or No credit OK'}
          text={
            'Our instant approval process is easy and fast. Get approved today!'
          }
          iconUrl={'/images/home/checkIcLand.svg'}
        />
        <AdvBox
          title={'Same day electronics pickup at Best Buy'}
          text={
            'Bring your products home today with same day pick up at Best Buy!'
          }
          iconUrl={'/images/home/labeIcLand.svg'}
        />
        <AdvBox
          title={'Furniture shipped directly to your home'}
          text={
            'Track order status in real-time, and enjoy the convenience of home delivery.'
          }
          iconUrl={'/images/home/carIcLand.svg'}
        />
        <AdvBox
          title={'Available nationwide'}
          text={'(Except MN, NJ, WI & WY)'}
          iconUrl={'/images/home/nationIcLand.svg'}
          wide
        />
        <AdvBox
          title={'Over 1 Million new, brand name, products'}
          text={
            'Chat live with our customer service team for help finding what you want and need.'
          }
          iconUrl={'/images/home/okIcLand.svg'}
          wide
        />
      </div>
    </div>
  );
}

export default React.memo(AdvantageSection);
