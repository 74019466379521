import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PlaceholderImage = ({ src, alt, width='100%', height='auto',className='',effect='',threshold='',position='static'}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };
  return (
    <div style={{position: position}}>
      {!isLoaded && (
      <div style={{width: width, height: height}}>
          <div style={{width: width, height: height,position: 'absolute'}} className="lazy-overlay"></div>
          <div className="bounce-loader">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
        </div>
      </div>
      )}
      <img
        className={className}
        onLoad={handleImageLoad}
        alt={alt}
        src={src}
        width={width}
        height={height}
        effect={effect}
        threshold={threshold}
        style={{
          display: isLoaded ? 'block' : 'none',
        }}
    />
    </div>
  );
};

export default PlaceholderImage;