export function getFAQ(updateQueryParams) {
  return [
    {
      id: 1,
      title: 'How does the process work?',
      htmlContent: (
        <div>
          {' '}
          Although the delivery/pickup process for our electronics store and
          furniture store differ slightly, the shopping and checkout process are
          the same. Simply get approved by clicking on the “get approved” button
          in either store. The approval process is fast and real-time. Once
          approved, simply shop, checkout, and sign your lease agreement.
          Electronics are fulfilled by Best Buy and most items are available for
          same day pickup. Furniture items from our furniture store are
          delivered to your doorstep. It’s really just that simple.
          <br />
          <br />
          As always, our customer service representatives are standing by on
          chat or can be reached via phone during normal business hours.
        </div>
      )
    },
    {
      id: 2,
      title: 'How do I get approved?',
      htmlContent: (
        <div>
          Simply click{' '}
          <a
            onClick={updateQueryParams}
            target="_blank"
            style={{
              cursor: 'pointer',
              fontWeight: '600',
              htmlContentDecoration: 'underline'
            }}>
            HERE
          </a>{' '}
          for furniture approval or click{' '}
          <a
            href='https://approval.buyontrust.info/?pid=3253&sid=9307&eid=upper%20corner&uid=yourUID'
            target="_blank"
            style={{
              cursor: 'pointer',
              fontWeight: '600',
              htmlContentDecoration: 'underline',
              color: 'inherit'
            }}>
            HERE
          </a>{' '} for electronics approval and answer all questions from the lease provider. Approvals/denials
          are presented immediately upon completion. When you get approved, you
          will be redirected to shop and checkout right away on BuyOnTrust.com
        </div>
      )
    },
    {
      id: 3,
      title: 'Can I grow my approval amounts?',
      htmlContent:
        'Simply make on-time payments, pay off your lease, and re-apply! We have seen approvals grow by as much as $1,000 from one lease to the next!'
    },
    {
      id: 4,
      title: 'Can I grow my credit score?',
      htmlContent:
        'While we can’t offer credit advice, we can recommend making on-time payments and paying off your debts. Credit is essentially a measure of trust. Simply make on-time payments, pay off your lease, and keep re-applying. The more leases you successfully complete the more trustworthiness you establish with the lease provider.'
    },
    {
      id: 5,
      title: 'Is bad credit OK? Is no credit OK?',
      htmlContent:
        'Yes, BuyOnTrust.com is a great place for customers to establish credit, and/or rebuild their credit if they have experienced a negative credit event. Simply make on-time payments, pay off your lease, and keep re-applying. The more leases you successfully complete the more trustworthiness you establish with the lease provider.'
    },
    {
      id: 6,
      title: 'What is my approval amount?',
      htmlContent: (
        <div>
          {' '}
          You should see an approval screen, and receive an email with your approval amount immediately once you are
          approved. If you don’t use your total approval amount on an order, you
          can contact the lease provider and ask them how much you have
          remaining from your previous lease. In general, this is usually
          calculated by subtracting your previous order value from your initial
          approval amount.
          <br />
          <br />
          Example: $1,000 initial approval - $650 order = $350 remaining
          approval amount.
        </div>
      )
    },
    {
      id: 7,
      title: 'How do I track my order?',
      htmlContent: (
        <div>
          To track your shipment, please follow your order tracking link. As it
          updates in real time, it will always give you the most current status
          of your order. Please also make sure you are watching for the courier
          on the date of your estimated delivery. If you need additional
          assistance, please send an email to our Quality Assurance team at:{' '}
          <a
            href="mailto:info@buyontrust.com"
            style={{
              color: '#4f4f4f',
              fontWeight: '600'
            }}>
            info@buyontrust.com
          </a>
          .
        </div>
      )
    },
    {
      id: 8,
      title: 'How does BuyOnTrust protect my identity?',
      htmlContent:
        'BuyOnTrust works with both local and federal law enforcement agencies, in a joint effort to protect consumers against identity fraud/theft. BuyOnTrust provides customers the ability to create a secure profile/account and a secure tracking link. BuyOnTrust only communicates with the phone number and/or email address associated with your order. Lastly, BuyOnTrust utilizes an identity verification process by way of your state-issued photo ID to best ensure you, and nobody else, is getting your items and taking responsibility for your lease.'
    },
    {
      id: 9,
      title: 'How quickly can I place a second order?',
      htmlContent: (
        <div>
          {' '}
          In general, you can place a second order right away to use your
          remaining approval amount.
          <br />
          <br />
          *Please note, additional orders relating to the same lease, are
          offered at the discretion of your lease provider. If you encounter an
          error placing a second order relating to the same lease, please
          contact your lease provider and review this error with them. They
          might have to approve the second order first on their end.
        </div>
      )
    }
  ];
}

export const reviews = [
  {
    rating: 5,
    text: `This is the best company ever! Really helpful people. Great customer service!`,
    img: {
      abbr: 'AH',
      color: '#fffbd6'
    },
    name: 'Antoine Holland'
  },
  {
    rating: 5,
    text: `I had the pleasure of being assisted by Jarai. He was very helpful and informative. I was indecisive and he went above and beyond answering all my questions and helping me choose the TV that best fits, both the size I was interested in and my budget. Jarai was also patient with me as the system gave me a few minor issues. He did an outstanding job with assisting me with my purchase and I could not be happier with my results.`,
    img: {
      abbr: 'C',
      color: '#d1f9ea'
    },
    name: 'Chris'
  },
  {
    rating: 5,
    text: `Service was great, fast lead times, showed up in half the time. Prices are affordable. Would highly recommend!!!`,
    img: {
      abbr: 'K',
      color: '#fddce5'
    },
    name: 'Kyle'
  },
  {
    rating: 5,
    text: `5 stars!! Excellent process, no hassle! Definitely refer a friend.`,
    img: {
      abbr: 'RJ',
      color: '#ffdebf'
    },
    name: 'Roger Jurek'
  },
  {
    rating: 5,
    text: `I had a fantastic experience using BuyOnTrust! The entire process was incredibly fast, and I have absolutely no complaints. Frank was especially helpful throughout, making the experience even more enjoyable. Thank you for such a seamless and efficient service!`,
    img: {
      abbr: 'V',
      color: '#d1f9ea'
    },
    name: 'V.'
  },
  {
    rating: 5,
    text: `The whole process was easy & smooth! Everyone was helpful and nice. Especially Frank Wright.`,
    img: {
      abbr: 'C',
      color: '#fddce5'
    },
    name: 'Christine'
  },
  {
    rating: 5,
    text: `Great customer service. Resolved my issue while on the phone and same day service for the item I wanted. Thanks!`,
    img: {
      abbr: 'J',
      color: '#fffbd6'
    },
    name: 'JT'
  },
  {
    rating: 5,
    text: `Harlem was great! Helped me place my order and helped me through some tasks that were working right. A+++++`,
    img: {
      abbr: 'J',
      color: '#ffdebf'
    },
    name: 'Jeremy'
  },
  {
    rating: 5,
    text: `****** was awesome very professional made me feel very welcomed and treated me with much respect. I appreciate you guys service thank you ****** once again a like you would make a person keep coming back.`,
    img: {
      abbr: 'SM',
      color: '#fddce5'
    },
    name: 'Stephanie M'
  },
  {
    rating: 5,
    text: `Good company this is the is the second time I have use them.`,
    img: {
      abbr: 'JA',
      color: '#d1f9ea'
    },
    name: 'James A'
  },
  {
    rating: 5,
    text: `Great service! Easy Transaction! Great experience! I do recommend them.`,
    img: {
      abbr: 'DG',
      color: '#fffbd6'
    },
    name: 'David G'
  },
  {
    rating: 5,
    text: `I love this! ****** was fantastic! It was a good experience.`,
    img: {
      abbr: 'CD',
      color: '#ffdebf'
    },
    name: 'Christopher D'
  },
  {
    rating: 5,
    text: `Great customer service, fast and knowledgeable experience, great online experience, great salesperson, we recommend to everyone!`,
    img: {
      abbr: 'RS',
      color: '#fddce5'
    },
    name: 'Richard S'
  },

  {
    rating: 5,
    text: `********** Was easy to work with. Smooth transaction, and the products were as advertised new.`,
    img: {
      abbr: 'WH',
      color: '#d1f9ea'
    },
    name: 'William H'
  }
];
