import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AdvBox({ title, text, iconUrl, wide = false }) {
  return (
    <div className={`advantage-box-sm ${wide && 'box-wide'}`}>      
        <LazyLoadImage
          className="slide-bg"
          alt="slider image"
          src={iconUrl}
          width="56px"
          height="auto"
        />
      <div>
        <h2 className={'adv-title'}>{title}</h2>
        <span className="adv-card-text font6">{text}</span>
      </div>
    </div>
  );
}

export default React.memo(AdvBox);
