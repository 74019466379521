import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EmblaCarousel } from '../../features/carousel/emblaCarousel';

function StoreElectornicsNavSection() {
  return (
    <div className={' store-nav-container reverse-store-nav-container'}>
      <div className={'col-md-5 store-msg-container'}>
        <h2 className={'tittle-reverse font5'}>
          Over 1 Million new, brand name, products
        </h2>
        <span className={'text-inf'}>
          Endless possibilities to always keep your space feeling fresh and
          welcoming.
        </span>
      </div>
      <div className={'col-md-5 pictures-container'}>
        <figure>
          <LazyLoadImage
            alt="furniture"
            src={'/images/bedroom/electronicsGroup_img.png'}
            width="100%"
            height="auto"
          />
        </figure>
      </div>
      
      <EmblaCarousel options={{ loop: true, align: 'start', watchDrag: false }} autoScroll={true}>
        <div className="brands-slider-embla">
          <figure className={'slider-item'}>
            <LazyLoadImage
              alt="brand"
              src="images/brands/Apple_logo.svg"
              effect="black and white"
              width="100%"
              height={40}
              threshold={500}
            />
          </figure>
          <figure className={'slider-item'}>
            <LazyLoadImage
              alt="brand"
              src="images/brands/Sony_logo.svg"
              effect="black and white"
              width="100%"
              height={40}
              threshold={500}
            />
          </figure>
          <figure className={'slider-item'}>
            <LazyLoadImage
              alt="brand"
              src="images/brands/samsung-logo.svg"
              effect="black and white"
              width="100%"
              height={40}
              threshold={500}
            />
          </figure>
          <figure className={'slider-item'}>
            <LazyLoadImage
              alt="brand"
              src="images/brands/Microsoft_logo.svg"
              effect="black and white"
              width="100%"
              height={40}
              threshold={500}
            />
          </figure>
          <figure className={'slider-item'}>
            <LazyLoadImage
              alt="brand"
              src="images/brands/LG_logo.svg"
              effect="black and white"
              width="100%"
              height={40}
              threshold={500}
            />
          </figure>
          <figure className={'slider-item'}>
            <LazyLoadImage
              alt="brand"
              src="images/brands/Dell-Logo.svg"
              effect="black and white"
              width="100%"
              height={40}
              threshold={500}
            />
          </figure>
        </div>
      </EmblaCarousel>
    </div>
  );
}

export default React.memo(StoreElectornicsNavSection);
