import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import FeaturedGallery from './featuredGallery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAxios } from '../../../hooks/useAxios/useAxios';

function FeaturedProductsSection() {
  const [activeTab, setActiveTab] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const productsUrl = `/public/buyontrust/products?page=${pageNumber}&limit=10`;
  const { data: prodList, isLoading, execute: getProducts } = useAxios(
    '',
    {},
    false
  );

  useEffect(() => {
    let updatedUrl;
    switch (activeTab) {
      case 0:
        updatedUrl = productsUrl;
        break;
      case 1:
        updatedUrl = `${productsUrl}&store_type=electronics`;
        break;
      case 2:
        updatedUrl = `${productsUrl}&store_type=furniture`;
        break;
    }

    getProducts({}, updatedUrl);
  }, [activeTab, pageNumber]);

  const onLoadMore = () => {
    setPageNumber(pageNumber + 1);
    const element = document.getElementById('featured-products');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="featured-products-container" id="featured-products">
      <LazyLoadImage className="featured-shape" src={'/images/shape.svg'} alt="Background shape" />
      <div className="featured-background" />
      <div className="featured-box">
        <h2 className="featured-title font5">FEATURED PRODUCTS</h2>
        <p className="featured-text font4">
          View some of our latest and most popular brand name products
        </p>
        <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
          <TabList className="featured-tabs">
            <Tab className="featured-btn font4">All</Tab>
            <Tab className="featured-btn font4">Electronics</Tab>
            <Tab className="featured-btn font4">Furniture</Tab>
          </TabList>
          <div className="featured-tab-panel">
            {isLoading ||
              (!prodList?.data && (
                <div className="featured-gallery-container">
                  {new Array(10).fill(1).map((item, index) => (
                    <div
                      className={`featured-gallery-card featured-gallery-card-${index} featured-gallery-card-skel`}
                      key={`skel-pro-${index}`}>
                      <div>
                        <div className="skel-pro skel-pro-grid"></div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}

            <TabPanel>
              <FeaturedGallery goods={prodList?.data} />
            </TabPanel>
            <TabPanel>
              <FeaturedGallery goods={prodList?.data} />
            </TabPanel>
            <TabPanel>
              <FeaturedGallery goods={prodList?.data} />
            </TabPanel>
          </div>
        </Tabs>
        <a className="ghost-btn font4" onClick={onLoadMore}>
          Load more
        </a>
      </div>
    </div>
  );
}

export default React.memo(FeaturedProductsSection);
